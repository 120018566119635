var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"tool"}},[_c('h2',[_vm._v("Job Pathways Exploration Tool")]),_c('p',[_vm._v(" This tool allows you to explore job pathways workers have experienced in the labor market. Each pathway consists of two transitions. Every job-to-job transition illustrated here has been made by at least 2,000 workers over the past decade. ")]),_c('div',{attrs:{"id":"histograms"}},[_vm._m(0),_vm._l((_vm.occHistograms),function(props,i){return _c('Histogram',_vm._b({key:("occFilter" + i)},'Histogram',Object.assign({}, props, {width: 300}),false))})],2),_c('div',[_c('h3',[_vm._v("2. Select an occupation")]),_c('Dropdown',_vm._b({on:{"selectOption":function (code) { return _vm.setOccFrom(code); }}},'Dropdown',{ selected: _vm.occ_from_code, occupations: _vm.filteredOccupations },false))],1),_c('div',{staticClass:"occupation"},[_c('div',{staticStyle:{"grid-row":"1/3"}},[_c('div',{ref:"destFilters",attrs:{"id":"destFilters"}},[_vm._m(1),_vm._l((Object.values(_vm.destFilters)),function(ref,i){
var key = ref.key;
var label = ref.label;
var selected = ref.selected;
return _c('div',{key:("label" + key),staticClass:"label",style:({
            color: selected ? _vm.colors.blue : _vm.colors.disabled,
            gridArea: ((i + 2) + " / 1 / " + (i + 3) + " / 2"),
          }),on:{"click":function($event){return _vm.updateFilter({ key: key, type: 'destFilters' })}}},[_vm._v(" "+_vm._s(label)+": ")])}),_vm._l((Object.values(
            _vm.destFilters
          )),function(ref,i){
          var key = ref.key;
          var description = ref.description;
          var selected = ref.selected;
return _c('div',{key:("desc" + key),staticClass:"description",style:({
            color: selected ? _vm.colors.blue : _vm.colors.disabled,
            gridArea: ((i + 2) + " / 2 / " + (i + 3) + " / 3"),
          }),on:{"click":function($event){return _vm.updateFilter({ key: key, type: 'destFilters' })}}},[_vm._v(" "+_vm._s(description)+" ")])})],2),_c('Pathway',_vm._b({on:{"hover":_vm.hover}},'Pathway',Object.assign({}, {id: 'tool',
          occ_from_code: _vm.occ_from_code,
          pathways: _vm.pathways},
          _vm.pathwayDimensions),false))],1),_c('div',{ref:"legend",staticClass:"legend",staticStyle:{"grid-column":"2/3","grid-row":"1/3"}},[_c('h4',[_vm._v("How to read these visualizations:")]),_c('img',{attrs:{"src":"images/demographic.jpg"}}),_c('img',{attrs:{"src":"images/path.jpg"}}),_c('img',{attrs:{"src":"images/overlapping.jpg"}})]),_c('div',{staticStyle:{"grid-column":"1/ 3"}},[_c('h3',[_vm._v("4. Explore occupation skills")]),_vm._l((_vm.skills),function(ref,i){
        var title = ref.title;
        var skills = ref.skills;
return _c('div',{key:("skill" + i),staticClass:"skills-container"},[(title)?_c('hr'):_vm._e(),(title)?_c('div',{staticClass:"title"},[_vm._v(_vm._s(title))]):_vm._e(),_vm._l((skills),function(skill){return _c('Skills',_vm._b({key:("skill" + (skill.id)),on:{"hover":_vm.hover}},'Skills',Object.assign({}, skill,
            {toSize: _vm.skillsSize,
            toInnerRadius: Math.max(0.1 * _vm.skillsSize, 36)}),false))})],2)})],2)]),_c('Hover',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.hovered),expression:"hovered"}]},'Hover',{ position: _vm.hoverPosition },false),[_c('div',{domProps:{"innerHTML":_vm._s(_vm.hovered && _vm.hovered.html)}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"grid-column":"1/4"}},[_c('h3',[_vm._v("1. Filter occupations")]),_c('em',[_vm._v("Click and drag along the horizontal axis to filter occupations by wage, gender and race.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"grid-column":"1/3"}},[_c('h3',[_vm._v(" 3. Explore possible job pathways ")]),_c('em',[_vm._v(" Click on the links below to see potential pathways from your chosen occupation: ")])])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade","appear":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"demographics",style:({
      width: (_vm.size + "px"),
      top: (_vm.y + "px"),
      left: (_vm.x + "px"),
      opacity: _vm.maskOpacity,
    })},[_c('div',{staticClass:"visuals",style:({ height: (_vm.size + "px") })},[_c('div',{staticClass:"labels"},[_c('Label',_vm._b({},'Label',{
            classes: 'title',
            position: 'absolute',
            align: 'bottom',
            offset: _vm.size,
            fontSize: _vm.fontSize,
          },false),[_c('span',[_vm._v(_vm._s(_vm.title))])])],1),_c('svg',{attrs:{"width":_vm.size,"height":_vm.size}},[_c('g',{staticClass:"background"},[_vm._l((_vm.background),function(ref,i){
          var label = ref.label;
          var path = ref.path;
return _c('g',{key:("bg" + i)},[_c('defs',[_c('path',{attrs:{"id":("labelPath-" + _vm.id + "-" + i),"d":path}})]),_c('text',{attrs:{"dy":"-.1em"}},[_c('textPath',{attrs:{"href":("#labelPath-" + _vm.id + "-" + i),"startOffset":"50%","text-anchor":"middle"}},[_vm._v(" "+_vm._s(label)+" ")])])])}),_c('circle',{attrs:{"r":_vm.starRadius,"fill":_vm.colors.STAR,"opacity":"0.75"}})],2),_vm._l((_vm.sides),function(ref,i){
          var paths = ref.paths;
          var transform = ref.transform;
return _c('g',{key:("side" + i),staticClass:"paths",attrs:{"transform":transform}},_vm._l((paths),function(ref,i){
          var path = ref.path;
          var fill = ref.fill;
          var stroke = ref.stroke;
return _c('path',{key:("path" + i),attrs:{"d":path,"fill":fill,"stroke":stroke}})}),0)})],2),_c('div',{staticClass:"labels"},[(_vm.occ_from)?_c('Label',_vm._b({},'Label',{ position: 'absolute' },false),[_c('h3',[_vm._v(_vm._s(_vm.hourly))])]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }
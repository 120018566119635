var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pathway"},[(_vm.pathways.length)?_c('div',[_c('svg',{attrs:{"width":_vm.width,"height":_vm.height}},[_c('mask',{attrs:{"id":("pathMask" + _vm.id)}},[_c('rect',{attrs:{"width":_vm.width,"height":_vm.height,"fill":"#fff","opacity":_vm.maskOpacity}}),_vm._l((_vm.maskPaths),function(ref){
var id = ref.id;
var path = ref.path;
var width = ref.width;
return _c('path',{key:("maskpath(" + id + ")"),attrs:{"d":path,"fill":"none","stroke":"#fff","stroke-width":width}})})],2),_c('transition-group',{attrs:{"tag":"g","mask":("url(#pathMask" + _vm.id + ")")},on:{"enter":_vm.enterLink,"leave":_vm.exitLink}},_vm._l((_vm.filteredLinks),function(ref){
var id = ref.id;
var path = ref.path;
var width = ref.width;
return _c('path',{key:("link" + id),staticClass:"link",attrs:{"d":path,"stroke-width":width,"stroke":_vm.colors.default},on:{"mousemove":function (e) { return _vm.hoverLink(e, id); },"mouseleave":function (e) { return _vm.hoverLink(e); }}})}),0),_vm._l((_vm.nodes),function(d){return _c('circle',{key:("node" + (d.id)),attrs:{"cx":d.x,"cy":d.y,"r":d.size / 2,"fill":"#fff"},on:{"mousemove":function (e) { return _vm.hoverDemographic(e, d); },"mouseleave":function (e) { return _vm.hoverDemographic(e); }}})})],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showNodes),expression:"showNodes"}]},_vm._l((_vm.filteredNodes),function(target,i){return _c('Demographics',_vm._b({key:("demographic" + i)},'Demographics',Object.assign({}, target,
          {innerRadius: 0,
          hasLabels: true}),false))}),1)]):_c('div',{staticClass:"empty"},[_vm._v(" No transitions found ")])])}
var staticRenderFns = []

export { render, staticRenderFns }
import { extent, min, max, scaleLinear, scaleOrdinal, format } from "d3";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["transitions"]),
    fontSize() {
      return 11;
    },
    duration() {
      return 0.25;
    },
    margin() {
      return { top: 10, right: 20, bottom: 10, left: 20 };
    },
    colors() {
      return {
        default: "#333",
        gray: "#999",
        disabled: "#cfcfcf",
        faded: "#efefef",
        purple: "#8C4799",
        red: "#E03C31",
        blue: "#62B5E5",
        STAR: "#FFB81C",
      };
    },
    colorScale() {
      return scaleOrdinal(
        [1, 0], // is or is not 23M
        [this.colors.purple, this.colors.blue]
      );
    },
    lineWidthScale() {
      return scaleLinear(
        extent(this.transitions, (d) => d.percent),
        [1, 40]
      );
    },
    sizeScale() {
      return scaleLinear(
        [
          min(this.transitions, (d) =>
            Math.min(d.source.weighted, d.target.weighted)
          ),
          max(this.transitions, (d) =>
            Math.max(d.source.weighted, d.target.weighted)
          ),
        ],
        [50, 200]
      );
    },
  },
  methods: {
    formatHourly(hourly) {
      return format("$.0f")(hourly);
    },
  },
};

import { format } from "d3";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    occ_from_code: "3600",
    originFilters: {
      hourly: {
        key: "hourly",
        label: "Hourly wage",
        format: format("$.0f"),
        access: ({ hourly }) => hourly,
        bounds: null,
      },
      "%poc": {
        key: "%poc",
        detailedLabel: "% in occupation who are BIPOC",
        label: "% BIPOC",
        format: format(".0%"),
        access: ({ demographics }) =>
          _.find(demographics, ({ key }) => key === "%poc").percent,
        bounds: null,
      },
      "%female": {
        key: "%female",
        detailedLabel: "% in occupation who are women",
        label: "% Women",
        format: format(".0%"),
        access: ({ demographics }) =>
          _.find(demographics, ({ key }) => key === "%female").percent,
        bounds: null,
      },
    },
    destFilters: {
      mostCommon: {
        key: "mostCommon",
        label: "Most common",
        description:
          "Most common job transitions that provided a wage gain of at least 10%.",
        getter: "mostCommonTransitions",
        selected: true,
      },
      nextBest: {
        key: "nextBest",
        label: "Next best job",
        description:
          "Skills-based transitions that yield a wage gain of at least 25%.",
        getter: "nextBestTransitions",
        selected: false,
      },
      stretchJob: {
        key: "stretchJob",
        label: "Stretch job",
        description:
          "Transitions that lead to wage gain of at least 50% and may require training.",
        getter: "stretchJobTransitions",
        selected: false,
      },
    },
  },
  getters: {
    occ_from({ occ_from_code }, getters, { occupations }) {
      return _.find(occupations, ({ code }) => occ_from_code === code);
    },
    filteredOccupations({ originFilters }, getters, { occupations }) {
      return _.filter(occupations, (d) =>
        _.every(originFilters, ({ access, bounds }) => {
          if (!bounds) return true;
          const val = access(d);
          return bounds[0] <= val && val <= bounds[1];
        })
      );
    },
    getPathways(state, getters, rootState, { getTransitions }) {
      return (occ_from_code, distance, percentDiff, sortType) => {
        const firsts = _.chain(
          getTransitions(occ_from_code, distance, percentDiff, sortType)
        )
          .take(5)
          .map((d) => {
            return { ...d, index: 1 };
          })
          .value();
        const seconds = _.chain(firsts)
          .map((first) => {
            const second = _.filter(
              // get children for first destinations
              getTransitions(
                first.target.code,
                null,
                (percentDiff) => percentDiff >= 0.1,
                "percent"
              ),
              // then make sure those children aren't in first destinations
              ({ target: { code } }) => occ_from_code !== code
            )[0];
            if (!second) return;
            return {
              ...second,
              distance: first.distance + second.distance,
              hourlyDiff: first.hourlyDiff + second.hourlyDiff,
              index: 2,
            };
          })
          .filter()
          .value();

        return _.union(firsts, seconds);
      };
    },
    mostCommonTransitions({ occ_from_code }, { getPathways }) {
      return getPathways(
        occ_from_code,
        null,
        (percentDiff) => percentDiff >= 0.1,
        "percent"
      );
    },
    nextBestTransitions({ occ_from_code }, { getPathways }) {
      return getPathways(
        occ_from_code,
        (distance) => distance <= 2.5,
        (percentDiff) => percentDiff >= 0.25,
        "percentDiff"
      );
    },
    stretchJobTransitions({ occ_from_code }, { getPathways }) {
      return getPathways(
        occ_from_code,
        (distance) => distance > 3,
        (percentDiff) => percentDiff >= 0.5,
        "percentDiff"
      );
    },
  },
  mutations: {
    setOccFrom(state, occ_from_code) {
      state.occ_from_code = occ_from_code;
    },
    setFilters(state, { type, filters }) {
      state[type] = filters;
    },
  },
  actions: {
    updateFilter({ commit, state }, { type, key, bounds }) {
      let filters = state[type];
      if (type === "originFilters") {
        filters[key].bounds = bounds;
      } else if (type === "destFilters") {
        _.each(filters, (val, filterKey) => {
          filters[filterKey].selected = filterKey === key;
        });
      }

      commit("setFilters", { type, filters });
    },
  },
};

<template>
  <transition name="fade" appear>
    <div
      class="demographics"
      v-show="show"
      :style="{
        width: `${size}px`,
        top: `${y}px`,
        left: `${x}px`,
        opacity: maskOpacity,
      }"
    >
      <div class="visuals" :style="{ height: `${size}px` }">
        <div class="labels">
          <Label
            v-bind="{
              classes: 'title',
              position: 'absolute',
              align: 'bottom',
              offset: size,
              fontSize,
            }"
          >
            <span>{{ title }}</span>
          </Label>
        </div>
        <svg :width="size" :height="size">
          <!-- BACKGROUND -->
          <g class="background">
            <g v-for="({ label, path }, i) in background" :key="`bg${i}`">
              <defs>
                <path :id="`labelPath-${id}-${i}`" :d="path" />
              </defs>
              <text dy="-.1em">
                <textPath
                  :href="`#labelPath-${id}-${i}`"
                  startOffset="50%"
                  text-anchor="middle"
                >
                  {{ label }}
                </textPath>
              </text>
            </g>
            <circle :r="starRadius" :fill="colors.STAR" opacity="0.75" />
          </g>
          <!-- RADARS -->
          <g
            class="paths"
            v-for="({ paths, transform }, i) in sides"
            :key="`side${i}`"
            :transform="transform"
          >
            <path
              v-for="({ path, fill, stroke }, i) in paths"
              :key="`path${i}`"
              :d="path"
              :fill="fill"
              :stroke="stroke"
            />
          </g>
        </svg>
        <div class="labels">
          <Label v-if="occ_from" v-bind="{ position: 'absolute' }">
            <h3>{{ hourly }}</h3>
          </Label>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import { scaleLinear, arc } from "d3";
import _ from "lodash";
import { gsap } from "gsap";

import Label from "./Label";
import Mixin from "./Mixin";

export default {
  name: "Demographics",
  mixins: [Mixin],
  components: { Label },
  props: {
    id: String,
    occ_from: Object,
    occ_to: Object,
    size: { type: Number, default: 100 },
    x: Number,
    y: Number,
    textAlign: String,
    hourlyDiff: Number,
    show: { type: Boolean, default: true },
    toMaskOpacity: { type: Number, default: 1 },
  },
  data() {
    return {
      background: [],
      sides: [],
      maskOpacity: this.toMaskOpacity,
    };
  },
  computed: {
    ...mapState(["demoKeys", "occupations"]),
    title() {
      if (this.occ_to) return this.occ_to.shortTitle || this.occ_to.title;
      return this.occ_from && (this.occ_from.shortTitle || this.occ_from.title);
    },
    hourly() {
      return this.formatHourly((this.occ_to || this.occ_from).hourly);
    },
    innerRadius() {
      return this.maxRadius - 7;
    },
    maxRadius() {
      return this.size / 2;
    },
    starRadius() {
      if (!this.occ_to && !this.occ_from) return;
      return (
        this.innerRadius *
        (this.occ_to || this.occ_from).demographics[2].percent
      );
    },
    hasLabels() {
      return this.size > 80;
    },
  },
  mounted() {
    this.calculateData();
  },
  watch: {
    occ_from() {
      this.calculateData();
    },
    occ_to() {
      this.calculateData();
    },
    toMaskOpacity() {
      gsap.to(this.$data, {
        duration: this.duration,
        maskOpacity: this.toMaskOpacity,
      });
    },
  },
  methods: {
    calculateData() {
      if (!this.occ_from) return;

      const padAngle = 0.1;
      const endAngle = Math.PI - padAngle;
      const arcGen = arc()
        .innerRadius(this.innerRadius)
        .outerRadius(this.maxRadius)
        .endAngle(endAngle);

      const startScale = scaleLinear([0, 1], [endAngle, padAngle]);
      this.sides = _.chain(this.occ_from.demographics)
        .filter(({ key }) => this.demoKeys[key].showFilter)
        .map(({ percent }, i) => {
          const percents = [{ percent: 1, fill: this.colors.faded }];
          if (this.occ_to) {
            percents.push({
              percent: this.occ_to.demographics[i].percent,
              fill: this.colorScale(this.occ_to.is23M),
            });
          }
          // occ_from
          percents.push({
            percent,
            fill: this.occ_to ? "none" : this.colorScale(this.occ_from.is23M),
            stroke: "#333",
          });
          return {
            transform: `scale(${i === 0 ? 1 : -1} 1)`,
            paths: _.map(percents, ({ percent, fill, stroke }) => {
              return {
                path: arcGen({ startAngle: startScale(percent) }),
                fill,
                stroke,
              };
            }),
          };
        })
        .value();

      // background
      this.background = _.chain(this.demoKeys)
        .filter(({ showFilter }) => showFilter)
        .map(({ label }, i) => {
          const angle = i * Math.PI - Math.PI / 2;
          const startAngle = angle + padAngle;
          const endAngle = angle + Math.PI - padAngle;
          const radius = this.innerRadius - this.fontSize;
          return {
            label: this.hasLabels ? label : "",
            path: this.calculateArc(startAngle, endAngle, radius, 0, 1),
          };
        })
        .value();
    },
    calculateArc(startAngle, endAngle, radius, largArcFlag, sweepFlag) {
      const x1 = radius * Math.cos(startAngle);
      const y1 = radius * Math.sin(startAngle);
      const x2 = radius * Math.cos(endAngle);
      const y2 = radius * Math.sin(endAngle);
      return `M${x1},${y1} A${radius},${radius} 0 ${largArcFlag} ${sweepFlag} ${x2},${y2}`;
    },
  },
};
</script>

<style scoped>
.demographics {
  position: absolute;
  display: inline-block;
  pointer-events: none;
}

svg,
.labels {
  position: absolute;
  left: 0;
  top: 0;
}

.visuals {
  position: relative;
  width: 100%;
}

.background text {
  font-size: 10px;
}

.background .white {
  fill: #fff;
  stroke: #fff;
  stroke-width: 2;
}

.paths {
  isolation: isolate;
}

.paths path {
  mix-blend-mode: multiply;
  fill-opacity: 0.75;
}
</style>

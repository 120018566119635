import Vue from "vue";
import Tool from "./Tool.vue";
import Story from "./Story.vue";
import store from "./store";

Vue.config.productionTip = false;

// load data
store.dispatch("getData");

// register the story
new Vue({
  render: (h) => h(Story),
  store,
}).$mount("#visual");

// register the tool
new Vue({
  render: (h) => h(Tool),
  store,
}).$mount("#tool");

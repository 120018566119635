import Vue from "vue";
import Vuex from "vuex";
import { csv } from "d3";
import _ from "lodash";

import tool from "./modules/tool";
import story from "./modules/story";

Vue.use(Vuex);

const skillgroups = require("../assets/data/skill_groups.json");

export default new Vuex.Store({
  modules: {
    tool,
    story,
  },
  state: {
    occupations: [],
    transitions: [],
    distance: 2.5,
    skillgroups,
    demoKeys: {
      "%poc": {
        key: "%poc",
        label: "% BIPOC",
        mapping: (d) => +d.pctnonwhite,
        showFilter: true,
      },
      "%female": {
        key: "%female",
        label: "% Women",
        mapping: (d) => +d.pctwomen,
        showFilter: true,
      },
      "%star": {
        key: "%star",
        label: "% Target Population",
        mapping: (d) => +d.of_occ,
      },
    },
  },
  getters: {
    getNextOccupations({ transitions }) {
      return (occ_from_code) => {
        return _.chain(transitions)
          .filter(({ source: { code } }) => code === occ_from_code)
          .uniqBy(({ target: { code } }) => code)
          .value();
      };
    },
    getTransitions(state, { getNextOccupations }) {
      return (occ_from_code, distance, percentDiff, sortType) => {
        return _.chain(getNextOccupations(occ_from_code))
          .filter(
            (d) =>
              (!distance || distance(d.distance)) &&
              (!percentDiff || percentDiff(d.percentDiff))
          )
          .sortBy((d) => -d[sortType])
          .value();
      };
    },
  },
  mutations: {
    setOccupations(state, occupations) {
      state.occupations = occupations;
    },
    setTransitions(state, transitions) {
      state.transitions = transitions;
    },
  },
  actions: {
    getData({ commit, state: { demoKeys } }) {
      Promise.all([
        csv("data/skills.csv"),
        csv("data/demographics.csv"),
        csv("./data/transitions.csv"),
      ]).then(([rawSkills, rawDemographics, rawTransitions]) => {
        const skillsByCode = _.keyBy(rawSkills, ({ occ2010 }) =>
          occ2010.padStart(4, "0")
        );
        const occupations = _.chain(rawDemographics)
          .map((d) => {
            const {
              occ2010,
              title,
              short_title,
              group,
              medhourw,
              all_workers,
              flag23,
            } = d;
            const code = occ2010.padStart(4, "0");
            const skills = skillsByCode[code];
            if (!skills) return;
            return {
              code,
              title,
              shortTitle: short_title,
              group,
              hourly: +medhourw,
              weighted: +all_workers,
              is23M: +flag23,
              demographics: _.map(demoKeys, ({ key, label, mapping }) => {
                return { key, label, percent: mapping(d) };
              }),
              skills: _.chain(skillgroups)
                .map(({ skill, group }) => {
                  skill = skill.replace(/\s/g, "").toLowerCase();
                  return {
                    id: `${group}-${skill}`,
                    skill,
                    group,
                    value: +skills[skill],
                  };
                })
                .sortBy("id")
                .map((d, i) => Object.assign(d, { index: i }))
                .value(),
            };
          })
          .filter()
          .value();
        const occByCode = _.keyBy(occupations, "code");

        const transitions = _.chain(rawTransitions)
          .map((d) => {
            const occ_from_code = d.occ_from.padStart(4, "0");
            const occ_to_code = d.occ_to.padStart(4, "0");

            // don't include if from and to are the same occupation
            // or if they don't exist in occupations array
            const source = occByCode[occ_from_code];
            const target = occByCode[occ_to_code];
            if (occ_from_code === occ_to_code || !source || !target) return;

            const hourlyDiff = target.hourly - source.hourly;
            return {
              id: `${occ_from_code}-${occ_to_code}`,
              weighted: +d.weighted,
              percent: +d.pct_trans,
              distance: +d.distance,
              source,
              target,
              hourlyDiff,
              percentDiff: hourlyDiff / source.hourly,
            };
          })
          .filter((d) => d && d.hourlyDiff > 0)
          .value();

        commit("setOccupations", occupations);
        commit("setTransitions", transitions);
      });
    },
  },
});

<template>
  <div class="dropdown">
    <label for="occ_from_select"
      >Occupation groups ({{ occGroups.length }})</label
    >
    <select v-model="occGroup">
      <option
        v-for="({ group, count }, i) in occGroups"
        :key="`group${i}`"
        :value="group"
        >{{ group }} ({{ count }})</option
      >
    </select>
    <label for="occ_from_select" style="padding-left: 5px">
      ⮑ Occupations ({{ occChildren.length }})</label
    >
    <select @change="(e) => $emit('selectOption', e.target.value)">
      <option
        v-for="({ code, title, is23M }, i) in occChildren"
        :key="`option${i}`"
        :value="code"
        :selected="selected === code"
        >{{ title }}{{ is23M ? "*" : "" }}</option
      >
    </select>
    <div style="grid-column: 2/3; font-size: .85em">
      <em>*occupations with high target population</em>
    </div>
  </div>
</template>

<script>
import _ from "lodash";

import Mixin from "./Mixin";

export default {
  name: "Dropdown",
  mixins: [Mixin],
  props: {
    selected: String,
    occupations: Array,
  },
  data() {
    return { occGroup: "" };
  },
  computed: {
    occGroups() {
      return _.chain(this.occupations)
        .groupBy("group")
        .map((occupations, group) => {
          return { group, count: occupations.length, occupations };
        })
        .sortBy(({ count }) => -count)
        .value();
    },
    occChildren() {
      if (!this.occGroup) return [];
      const { occupations } = _.find(
        this.occGroups,
        ({ group }) => this.occGroup === group
      );
      return _.sortBy(occupations, "title");
    },
  },
  mounted() {
    this.changeSelected();
  },
  watch: {
    selected() {
      this.changeSelected();
    },
    occGroups() {
      this.changeSelected();
    },
    occChildren() {
      // if occupation exists in occChildren do nothing
      if (_.find(this.occChildren, ({ code }) => code === this.selected))
        return;
      // else set to first child
      this.$emit("selectOption", this.occChildren[0].code);
    },
  },
  methods: {
    changeSelected() {
      if (!this.occGroups.length) return;
      // see if the selected occupation exists
      const occupation = _.find(
        this.occupations,
        ({ code }) => code === this.selected
      );
      if (occupation) {
        // if it does, set occGroup to it selected occupation's group
        this.occGroup = occupation.group;
      } else {
        // else set occGroup to first in occGroups
        this.occGroup = this.occGroups[0].group;
        // and set occupation to first occupation in the group
        this.$emit("selectOption", this.occGroups[0].occupations[0].code);
      }
    },
  },
};
</script>

<style scoped>
.dropdown {
  display: grid;
  grid-template-columns: max-content auto;
  grid-gap: 10px 20px;
  width: 100%;
}
</style>

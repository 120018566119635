<template>
  <div :class="`label ${classes || ''}`" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Label",
  props: {
    classes: String,
    position: { type: String, default: "relative" },
    align: { type: String, default: "center" },
    offset: { type: Number, default: 0 },
    fontSize: { type: [Number, String], default: "auto" },
  },
  computed: {
    style() {
      const { position, align, offset, fontSize } = this;
      const style = {
        fontSize: fontSize === "auto" ? fontSize : `${fontSize}px`,
      };
      if (position === "relative") return style;

      Object.assign(style, { position, left: 0, top: `${offset / 2}px` });
      if (align === "center") {
        return Object.assign(style, {
          left: `${offset / 2}px`,
          transform: "translate(-50%, -50%)",
        });
      } else if (align === "bottom") {
        return Object.assign(style, {
          transform: "translate(-50%, 0%)",
        });
      }
      return Object.assign(style, {
        top: `${-offset / 2}px`,
        transform: "translate(-50%, -100%)",
      });
    },
  },
};
</script>

<style scoped>
.label {
  text-align: center;
  margin: 0;
}

.label.title {
  position: absolute;
  width: 160px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.label.title span {
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.8);
  padding: 0 2px;
}

h1,
h2,
h3,
h4 {
  margin: 0;
}
</style>

<template>
  <div class="pathway">
    <div v-if="pathways.length">
      <svg :width="width" :height="height">
        <mask :id="`pathMask${id}`">
          <rect
            :width="width"
            :height="height"
            fill="#fff"
            :opacity="maskOpacity"
          />
          <path
            v-for="{ id, path, width } in maskPaths"
            :key="`maskpath(${id})`"
            :d="path"
            fill="none"
            stroke="#fff"
            :stroke-width="width"
          />
        </mask>
        <transition-group
          tag="g"
          @enter="enterLink"
          @leave="exitLink"
          :mask="`url(#pathMask${id})`"
        >
          <path
            class="link"
            v-for="{ id, path, width } in filteredLinks"
            :key="`link${id}`"
            :d="path"
            :stroke-width="width"
            :stroke="colors.default"
            @mousemove="(e) => hoverLink(e, id)"
            @mouseleave="(e) => hoverLink(e)"
          />
        </transition-group>
        <circle
          v-for="d in nodes"
          :key="`node${d.id}`"
          :cx="d.x"
          :cy="d.y"
          :r="d.size / 2"
          fill="#fff"
          @mousemove="(e) => hoverDemographic(e, d)"
          @mouseleave="(e) => hoverDemographic(e)"
        />
      </svg>
      <div v-show="showNodes">
        <Demographics
          v-for="(target, i) in filteredNodes"
          v-bind="{
            ...target,
            innerRadius: 0,
            hasLabels: true,
          }"
          :key="`demographic${i}`"
        />
      </div>
    </div>
    <div v-else class="empty">
      No transitions found
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import {
  max,
  format,
  scaleLinear,
  scalePoint,
  forceSimulation,
  forceCollide,
  forceX,
  forceY,
} from "d3";
import { gsap } from "gsap";

import Mixin from "./Mixin";
import Demographics from "./Demographics.vue";

export default {
  name: "Pathway",
  components: { Demographics },
  mixins: [Mixin],
  props: {
    id: String,
    width: Number,
    height: Number,
    showNodes: { type: [Array, String], default: "all" },
    showLinks: { type: [Array, String], default: "all" },
    highlightedNodes: Array,
    highlightedLinks: Array,
    toMaskOpacity: { type: Number, default: 1 },
    collideRadius: Number,
    occ_from_code: String,
    occ_to_codes: Array,
    pathways: Array,
  },
  data() {
    return {
      source: {},
      nodes: [],
      links: [],
      filteredNodes: [],
      filteredLinks: [],
      maskPaths: [],
      maskOpacity: this.toMaskOpacity,
    };
  },
  mounted() {
    this.calculateData();
    this.animateData();
  },
  watch: {
    pathways() {
      this.calculateData();
      this.animateData();
    },
    showNodes() {
      this.animateData();
    },
    showLinks() {
      this.animateData();
    },
    filteredLinks() {
      this.highlightLinks(this.highlightedLinks);
    },
    highlightedNodes() {
      this.highlightNodes(this.highlightedNodes);
    },
    highlightedLinks() {
      this.highlightLinks(this.highlightedLinks);
    },
    toMaskOpacity() {
      gsap.to(this.$data, {
        duration: this.duration,
        maskOpacity: this.toMaskOpacity,
      });
    },
  },
  methods: {
    calculateData() {
      if (!this.pathways || !this.pathways.length) return;

      // scales
      const xScale = scalePoint(
        _.range(max(this.pathways, (d) => d.index) + 1),
        [0, this.width]
      );
      const maxHourly = max(this.pathways, (d) => d.hourlyDiff);
      const yScale = scaleLinear([0, maxHourly], [this.height, 0]);

      // nodes
      const { source } = _.find(
        this.pathways,
        ({ source: { code } }) => code === this.occ_from_code
      );
      const x0 = xScale(0);
      const y0 = yScale(0);
      const nodes = [
        {
          id: this.occ_from_code,
          code: this.occ_from_code,
          x: x0,
          y: y0,
          fx: x0,
          fy: y0,
          size: this.sizeScale(source.weighted),
          occ_from: source,
          occ_to: null,
          toMaskOpacity: 1,
        },
      ];
      _.chain(this.pathways)
        .uniqBy(({ target: { code } }) => code)
        .each(({ hourlyDiff, index, target }) => {
          const x = xScale(index);
          const y = yScale(hourlyDiff);
          nodes.push({
            id: `${source.code}-${target.code}`,
            code: target.code,
            focusX: x,
            focusY: y,
            x,
            y,
            size: this.sizeScale(target.weighted),
            hourlyDiff,
            occ_from: source,
            occ_to: target,
            toMaskOpacity: 1,
          });
        })
        .value();

      // simulation
      const simulation = forceSimulation(nodes)
        .force(
          "collide",
          forceCollide(
            // either use the passed in radius or the node's radius
            (d) => (this.collideRadius || d.size / 2) + this.fontSize
          )
        )
        .force(
          "x",
          forceX((d) => d.focusX)
        )
        .force(
          "y",
          forceY((d) => d.focusY)
        )
        .stop();
      _.times(250, () => {
        simulation.tick();
        // make sure the nodes are within the bounds
        _.each(nodes, (d) => {
          const radius = this.collideRadius || d.size / 2;
          // left, right
          d.x = Math.max(d.size / 2, Math.min(this.width - d.size / 2, d.x));
          // top, bottom
          d.y = Math.max(radius + 20, Math.min(this.height - radius - 20, d.y));
        });
      });
      this.nodes = nodes;

      // links
      this.links = _.map(this.pathways, ({ id, percent }) => {
        const [source, target] = id.split("-");
        const { x: x1, y: y1 } = _.find(
          this.nodes,
          ({ code }) => source === code
        );
        const { x: x2, y: y2 } = _.find(
          this.nodes,
          ({ code }) => target === code
        );

        return {
          id,
          path: `
            M${x1},${y1}
            ${this.calculateCurvePoint(x1, x2, y1, y2)}
          `,
          width: this.lineWidthScale(percent),
        };
      });

      this.$emit("finishCalculations", {
        nodes: this.nodes,
        links: this.links,
      });
    },
    calculateCurvePoint(x1, x2, y1, y2) {
      const mx = (x1 + x2) / 2;
      const my = (y1 + y2) / 2;
      const length = Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2)) / 4;
      const slope = y1 !== y2 ? (x1 - x2) / (y2 - y1) : x2 - x1; // perpendicular slope

      const cx = -length / Math.sqrt(1 + Math.pow(slope, 2));
      return `Q${cx + mx},${slope * cx + my} ${x2},${y2}`;
    },
    animateData() {
      if (!this.nodes.length || !this.links.length) return;
      if (this.showNodes === "all" && this.showLinks === "all") {
        const tl = gsap.timeline();
        let filteredNodes = [this.nodes[0]];
        let filteredLinks = [];
        _.chain(this.nodes)
          .sortBy("x")
          .each((node, index) => {
            const links = _.filter(this.links, ({ id }) => {
              const [, target] = id.split("-");
              return target === node.code;
            });

            filteredNodes = _.union(filteredNodes, [node]);
            filteredLinks = _.union(filteredLinks, links);
            tl.set(this.$data, { filteredLinks }, 0.15 * index);
            tl.set(this.$data, { filteredNodes }, 0.15 * index);
          })
          .value();
      } else {
        this.filteredNodes =
          this.showNodes === "all"
            ? this.nodes
            : _.filter(this.nodes, ({ id }) => _.includes(this.showNodes, id));
        this.filteredLinks =
          this.showLinks === "all"
            ? this.links
            : _.filter(this.links, ({ id }) => _.includes(this.showLinks, id));
      }
    },
    enterLink(path, done) {
      const length = path.getTotalLength();
      gsap.fromTo(
        path,
        { strokeDasharray: length, strokeDashoffset: length },
        { strokeDashoffset: 0, duration: this.duration, onComplete: done }
      );
    },
    exitLink(path, done) {
      const length = path.getTotalLength();
      gsap.fromTo(
        path,
        { strokeDasharray: length, strokeDashoffset: 0 },
        { strokeDashoffset: length, duration: this.duration, onComplete: done }
      );
    },
    highlightNodes(highlighted) {
      highlighted = highlighted || [];
      this.filteredNodes = _.map(this.filteredNodes, (d) =>
        Object.assign(d, {
          toMaskOpacity:
            !highlighted.length || _.includes(highlighted, d.id) ? 1 : 0.25,
        })
      );
    },
    highlightLinks(highlighted) {
      highlighted = highlighted || [];
      this.maskPaths = _.filter(this.filteredLinks, ({ id }) =>
        _.includes(highlighted, id)
      );
    },
    hoverDemographic(e, demographic) {
      const hoverPosition = {
        x: e.clientX,
        y: e.clientY,
      };
      if (!demographic) {
        this.highlightNodes(this.highlightedNodes);
        this.maskOpacity = this.toMaskOpacity;
        // if already hovered, unhover
        this.$emit("hover", { hovered: null, hoverPosition });
      } else {
        const { id, occ_from, occ_to } = demographic;
        const occ = occ_to || occ_from;

        // highlight nodes and links it's connected to
        this.highlightNodes([demographic.id]);
        this.maskOpacity = 0.25;

        this.$emit("hover", {
          hoverPosition,
          hovered: {
            key: id,
            html: `
              <h4><span class='occ_title from'
                style='background-color: ${this.colorScale(occ.is23M)}'>
                ${occ.title}
              </span></h4>
              <br />
              <div style='display: grid; grid-template-columns: auto max-content'>
                <div><strong>Hourly wage</strong></div>
                <div>$${occ.hourly}</div>
                <div><strong>Number of workers</strong></div>
                <div>${format(",.0f")(occ.weighted)}k</div>
                ${occ.demographics
                  .map(
                    ({ label, percent }) =>
                      `<div><strong>${label}</strong></div>
                      <div>${format(".1%")(percent)}</div>`
                  )
                  .join("")}
              </div>
            `,
          },
        });
      }
    },
    hoverLink(e, linkId) {
      const hoverPosition = {
        x: e.clientX,
        y: e.clientY,
      };

      if (!linkId) {
        this.highlightNodes(this.highlightedNodes);
        this.highlightLinks(this.highlightedLinks);
        this.maskOpacity = this.toMaskOpacity;
        // if already hovered, unhover
        this.$emit("hover", { hovered: null, hoverPosition });
      } else {
        this.maskOpacity = 0.25;
        this.highlightNodes([linkId]);
        this.highlightLinks([linkId]);

        const { percent, weighted, source, target } = _.find(
          this.pathways,
          ({ id }) => id === linkId
        );
        this.$emit("hover", {
          hoverPosition,
          hovered: {
            key: linkId,
            html: `
            <strong>${format(",.0f")(weighted)}k</strong>
            (or ${format(".1%")(percent)}) of
            <strong>${source.title}</strong>
            made the transition to
            <span class='occ_title from'
              style='background-color: ${this.colorScale(target.is23M)}'>
              ${target.title}</span>
            in the past 10 years.
            `,
          },
        });
      }
    },
  },
};
</script>

<style scoped>
.pathway {
  width: 100%;
  height: 100%;
  max-height: calc(100vh - 60px);
  position: relative;
  display: inline-block;
  vertical-align: top;
}

path.link {
  fill: none;
  opacity: 0.25;
}

.empty {
  color: #cfcfcf;
  text-align: center;
  margin-top: 50%;
  transform: translate(0, -50%);
}
</style>

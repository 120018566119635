<template>
  <div class="hovered" :style="{ left: `${x}px`, top: `${y}px`, transform }">
    <slot></slot>
  </div>
</template>

<script>
import { scaleLinear } from "d3";

export default {
  name: "hover",
  props: ["position"],
  data() {
    return {
      x: null,
      y: null,
      transform: null,
    };
  },
  created() {
    this.xScale = scaleLinear();
    this.yScale = scaleLinear();
  },
  watch: {
    position() {
      this.$nextTick(() => {
        this.positionRect();
      });
    },
  },
  methods: {
    positionRect() {
      if (!this.position) return;

      const rectWidth = this.$el.offsetWidth;
      this.xScale
        .domain([0, window.innerWidth])
        .range([0, window.innerWidth - rectWidth]);

      const { x, y } = this.position;
      const isTop = y < window.innerHeight / 2;

      this.x = this.xScale(x);
      this.y = y + (isTop ? 1 : -1) * 15;
      this.transform = !isTop ? "translate(0, -100%)" : "translate(0, 0)";
    },
  },
};
</script>

<style scoped>
.hovered {
  position: fixed;
  color: #000;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid #cfcfcf;
  pointer-events: none;
  padding: 15px;
  min-width: 240px;
  max-width: 360px;
  z-index: 1000;
  line-height: 1.6;
  top: 150px;
  left: 0px;
  text-align: left;
}

img {
  max-width: 240px;
  max-height: 240px;
  display: block;
  margin: auto;
}

h3 {
  margin: 0;
  margin-bottom: 5px;
}
</style>

<template>
  <transition name="fade">
    <div
      v-show="show"
      class="skills"
      :style="{
        position,
        width: `${size}px`,
        left: `${x}px`,
        top: `${y}px`,
      }"
    >
      <transition name="fade">
        <Label
          v-if="showLabels"
          v-bind="{
            classes: inPathway ? 'title' : '',
            position,
            align: inPathway ? 'bottom' : 'top',
            offset: size,
            fontSize: inPathway ? fontSize : 16,
          }"
        >
          <span v-if="inPathway">{{ title.toTitle || title.fromTitle }}</span>
          <div v-else>
            <div>
              <span
                class="occ_title from"
                :style="{ backgroundColor: fromColor }"
                >{{ title.fromTitle }}</span
              >
            </div>
            <div v-if="title.toTitle">
              to
              <span
                class="occ_title to"
                :style="{
                  borderColor: `${toColor}`,
                }"
              >
                {{ title.toTitle }}</span
              >
            </div>
          </div>
        </Label>
      </transition>
      <div class="visuals" :style="{ height: `${size}px` }">
        <canvas
          ref="canvas"
          :width="2 * canvasSize"
          :height="2 * canvasSize"
          :style="{
            width: `${canvasSize}px`,
            height: `${canvasSize}px`,
            top: position === 'relative' && `${size / 2}px`,
            left: position === 'relative' && `${size / 2}px`,
          }"
        />
        <svg
          v-if="showLabels"
          :width="size"
          :height="size"
          :viewBox="
            position === 'relative'
              ? `${-size / 2} ${-size / 2} ${size} ${size}`
              : `0 0 ${size} ${size}`
          "
        >
          <!-- GROUP LABELS -->
          <g v-if="!inPathway">
            <g v-for="({ path, text }, i) in groups" :key="`group${i}`">
              <defs>
                <path :id="`groupPath-${id}-${i}`" :d="path" />
              </defs>
              <text :font-size="groupLabelSize">
                <textPath
                  :href="`#groupPath-${id}-${i}`"
                  startOffset="50%"
                  text-anchor="middle"
                >
                  {{ text }}
                </textPath>
              </text>
            </g>
          </g>
          <!-- HOVER SKILL -->
          <path
            class="petals"
            v-for="{ skill, path } in petals"
            :key="`petal-${skill}`"
            :d="path"
            @mousemove="(e) => showLabels && hoverPetal(e, skill)"
            @mouseleave="hoverPetal"
          />
        </svg>
        <div class="labels">
          <transition name="fade">
            <Label
              v-bind="{
                position: 'absolute',
                align: 'center',
                offset: position === 'relative' ? size : 0,
              }"
              v-if="showLabels"
            >
              <h3>{{ hourly }}</h3>
            </Label>
          </transition>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapState } from "vuex";
import { arc, scaleLinear, scaleOrdinal } from "d3";
import _ from "lodash";
import { gsap } from "gsap";

import Mixin from "./Mixin";
import Label from "./Label";

export default {
  name: "Skills",
  mixins: [Mixin],
  components: { Label },
  props: {
    id: String,
    position: { type: String, default: "relative" },
    occ_from: Object,
    occ_to: Object,
    highlighted: { type: Array },
    showLabels: { type: Boolean, default: true },
    inPathway: { type: Boolean, default: false },
    // FOR ANIMATION
    show: { type: Boolean, default: true },
    toMaskOpacity: { type: Number, default: 1 },
    toX: { type: Number, default: 0 },
    toY: { type: Number, default: 0 },
    toSize: { type: Number, default: 280 },
    toInnerRadius: { type: Number, default: 28 },
  },
  data() {
    return {
      x: this.toX,
      y: this.toY,
      size: this.toSize,
      innerRadius: this.toInnerRadius,
      // HACKY SOLUTION bc Canvas clears the drawing
      // when the size resets, so set Canvas dimensions
      // bigger than the biggest size we'd draw
      canvasSize: 750,
      groups: [],
      groupPadding: 0.075,
      petals: [],
      petalPadding: 0.035,
      maskOpacity: this.toMaskOpacity,
      hoveredPetal: null,
    };
  },
  computed: {
    ...mapState(["occupations", "skillgroups"]),
    fromColor() {
      return this.occ_from && this.colorScale(this.occ_from.is23M);
    },
    toColor() {
      return this.occ_to && this.colorScale(this.occ_to.is23M);
    },
    groupLabelSize() {
      return Math.min((this.size / 240) * this.fontSize, 18);
    },
    maxRadius() {
      return (
        this.size / 2 -
        (this.showLabels && !this.inPathway ? this.margin.left : 0)
      );
    },
    radiusScale() {
      return scaleLinear([1, 5], [this.innerRadius, this.maxRadius]);
    },
    perAngle() {
      const { groupPadding, petalPadding, skillgroups } = this;
      const numGroups = _.uniqBy(skillgroups, "group").length;

      return (
        // subtract group padding, then divide that by number of skills
        (2 * Math.PI - numGroups * groupPadding) / skillgroups.length -
        petalPadding
      );
    },
    angleScale() {
      const { groupPadding, petalPadding, skillgroups, perAngle } = this;
      let angle = -groupPadding / 2;
      const range = _.chain(skillgroups)
        .groupBy("group")
        .map((skills) => {
          // for each new group, add groupPadding
          angle += groupPadding;
          // then calculate angle for each skill in group
          return _.map(skills, () => {
            const skillAngle = angle;
            angle += perAngle + petalPadding;
            return skillAngle;
          });
        })
        .flatten()
        .value();
      return scaleOrdinal(_.map(skillgroups, "key"), range);
    },
    arcGen() {
      return arc()
        .innerRadius(this.innerRadius)
        .outerRadius(this.maxRadius);
    },
    title() {
      const fromTitle =
        this.occ_from && (this.occ_from.shortTitle || this.occ_from.title);
      const toTitle =
        this.occ_to && (this.occ_to.shortTitle || this.occ_to.title);
      return { fromTitle, toTitle };
    },
    hourly() {
      if (!this.occ_from) return;
      if (!this.occ_to) return this.formatHourly(this.occ_from.hourly);
      if (this.inPathway) return this.formatHourly(this.occ_to.hourly);

      const diff = this.occ_to.hourly - this.occ_from.hourly;
      return `${diff < 0 ? "–" : 0 < diff ? "+" : ""}${this.formatHourly(
        Math.abs(diff)
      )}`;
    },
  },
  mounted() {
    this.ctx = this.$refs.canvas.getContext("2d");
    this.ctx.globalCompositeOperation = "multiply";
    this.ctx.scale(2, 2);
    this.ctx.translate(this.canvasSize / 2, this.canvasSize / 2);

    this.render();
  },
  watch: {
    occ_from() {
      this.render();
    },
    occ_to() {
      this.render();
    },
    showLabels() {
      this.render();
    },
    toMaskOpacity() {
      gsap.to(this.$data, {
        duration: this.duration,
        maskOpacity: this.toMaskOpacity,
      });
    },
    maskOpacity() {
      this.render();
    },
    toX() {
      gsap.to(this.$data, {
        duration: 2 * this.duration,
        x: this.toX,
      });
    },
    toY() {
      gsap.to(this.$data, {
        duration: 2 * this.duration,
        y: this.toY,
      });
    },
    toSize() {
      gsap.to(this.$data, {
        duration: 2 * this.duration,
        size: this.toSize,
        innerRadius: this.toInnerRadius,
      });
    },
    size() {
      this.render();
    },
    highlighted() {
      this.render();
    },
    hoveredPetal() {
      this.maskOpacity = this.hoveredPetal ? 0.25 : this.toMaskOpacity;
      this.render();
    },
  },
  methods: {
    render() {
      if (!this.occ_from) return;

      // reset
      this.ctx.clearRect(
        -this.canvasSize / 2,
        -this.canvasSize / 2,
        this.canvasSize,
        this.canvasSize
      );

      // groups
      if (this.showLabels) {
        // background circle
        this.ctx.beginPath();
        this.ctx.fillStyle = "#fff";
        this.ctx.arc(0, 0, this.maxRadius, 0, 2 * Math.PI);
        this.ctx.fill();

        this.calculateGroups();
        this.calculatePetals();
      } else {
        this.groups = [];
        this.petals = [];
      }

      // skills petals
      this.renderPetals(this.occ_from, this.fromColor, null, 0.75);
      this.ctx.lineWidth = 1.5;
      this.occ_to && this.renderPetals(this.occ_to, null, this.toColor, 1);
    },
    calculateGroups() {
      const { perAngle, angleScale, radiusScale } = this;
      // groups

      const outerRadius = radiusScale(5);
      this.groups = _.chain(this.skillgroups)
        .groupBy("group")
        .map((skills, group) => {
          let startAngle = angleScale(_.first(skills).key) - Math.PI / 2;
          let endAngle =
            angleScale(_.last(skills).key) + perAngle - Math.PI / 2;
          const angle = (startAngle + endAngle) / 2;

          // render faint inner lines
          this.ctx.strokeStyle = "#efefef";
          this.ctx.lineWidth = 1;
          _.times(3, (i) => {
            const value = i + 2;
            const radius = radiusScale(value);
            this.renderGroupArc(radius, startAngle, endAngle);
            // only draw the numbers for every other
            if (value <= 2) return;
            this.renderGroupText(radius, angle, value);
          });

          // render thicker outer line
          this.ctx.strokeStyle = "#333333";
          this.ctx.lineWidth = 3;
          this.renderGroupArc(outerRadius, startAngle, endAngle);

          // calculate text path for group label
          return this.calculateGroupLabel(
            outerRadius,
            angle,
            startAngle,
            endAngle,
            group
          );
        })
        .flatten()
        .filter()
        .value();
    },
    calculatePetals() {
      const { angleScale, perAngle, arcGen, petalPadding } = this;
      this.petals = _.map(this.skillgroups, ({ key }) => {
        const angle = angleScale(key);
        return {
          skill: key,
          path: arcGen({
            startAngle: angle - petalPadding,
            endAngle: angle + perAngle + petalPadding,
          }),
        };
      });
    },
    calculateGroupLabel(radius, angle, startAngle, endAngle, text) {
      // take the first two words
      let texts = _.take(text.split(" "), 2);
      // calculate circumference
      const circum = (endAngle - startAngle) * radius;
      if (texts.join(" ").length * this.groupLabelSize * 0.45 < circum) {
        // if the whole label fits in one ring
        texts = [texts.join(" ")];
      } else if (
        !_.every(
          texts,
          (text) => text.length * this.groupLabelSize * 0.45 < circum
        )
      ) {
        // if needs to be split in two, make sure every line fits
        return;
      }
      // and see if this label is on bottom half
      const isBottom = 0 <= angle && angle <= Math.PI;

      return _.map(texts, (text, i) => {
        let r = radius;
        if (isBottom) {
          r += (i + 1) * this.groupLabelSize;
        } else {
          r += (i + 0.2) * this.groupLabelSize;
        }
        const x1 = r * Math.cos(startAngle);
        const y1 = r * Math.sin(startAngle);
        const x2 = r * Math.cos(endAngle);
        const y2 = r * Math.sin(endAngle);
        let path;

        // if angle is bottom half
        if (isBottom) {
          path = `M${x2},${y2} A${r},${r} 0 0 0 ${x1},${y1}`;
        } else {
          path = `M${x1},${y1} A${r},${r} 0 0 1 ${x2},${y2}`;
        }
        return { path, text };
      });
    },
    renderGroupArc(radius, startAngle, endAngle) {
      this.ctx.beginPath();
      this.ctx.arc(0, 0, radius, startAngle, endAngle);
      this.ctx.stroke();
    },
    renderGroupText(radius, angle, text) {
      // const x = radius * Math.cos(angle);
      // const y = radius * Math.sin(angle);
      this.ctx.save();
      this.ctx.textAlign = "center";
      this.ctx.fillStyle = "#cfcfcf";
      this.ctx.rotate(angle + Math.PI / 2);
      this.ctx.clearRect(-3.5, -radius - 1, 7, 2);
      this.ctx.fillText(text, 0, -radius + 3.5);
      this.ctx.restore();
    },
    renderPetals({ skills }, fromColor, toColor, opacity) {
      const {
        ctx,
        radiusScale,
        innerRadius,
        perAngle,
        angleScale,
        maskOpacity,
        hoveredPetal,
        highlighted,
      } = this;
      return _.each(skills, ({ skill, value }) => {
        const angle = angleScale(skill) + perAngle / 2;
        const outerRadius = radiusScale(value);
        const startAngle = -Math.PI / 2 - perAngle / 2;
        const endAngle = -Math.PI / 2 + perAngle / 2;
        // bottom
        const x1 = innerRadius * Math.cos(startAngle); // bottom left
        const y1 = innerRadius * Math.sin(startAngle);
        // mid
        const midx = outerRadius * Math.cos(-Math.PI / 2); // lol this is just 0
        const midy = outerRadius * Math.sin(-Math.PI / 2); // lol this is -outerRadius
        // top
        const outerPad = Math.max(
          outerRadius * (1 - perAngle / 3), // how much below outer radius depends on radius
          innerRadius
        );
        const x3 = outerPad * Math.cos(endAngle); // top left
        const y3 = outerPad * Math.sin(endAngle);
        const x4 = outerPad * Math.cos(startAngle); // top right
        const y4 = outerPad * Math.sin(startAngle);

        // start rendering
        ctx.save();
        ctx.rotate(angle);
        ctx.beginPath();
        // path
        ctx.moveTo(x1, y1);
        ctx.arc(0, 0, innerRadius, startAngle, endAngle);
        ctx.lineTo(x3, y3);
        ctx.quadraticCurveTo(x3 / 2, midy, midx, midy);
        ctx.quadraticCurveTo(x4 / 2, midy, x4, y4);
        ctx.lineTo(x1, y1);

        // opacity
        let faded = false;
        if (hoveredPetal) {
          faded = hoveredPetal !== skill;
        } else if (highlighted) {
          faded = !_.includes(highlighted, skill);
        }
        ctx.globalAlpha = (!faded ? 1 : maskOpacity) * opacity;

        // fill & stroke
        ctx.fillStyle = fromColor;
        fromColor && ctx.fill();
        ctx.strokeStyle = toColor;
        toColor && ctx.stroke();

        // restore
        ctx.restore();
      });
    },
    hoverPetal(e, key) {
      this.hoveredPetal = key;
      const hoverPosition = {
        x: e.clientX,
        y: e.clientY,
      };
      if (!key) {
        this.$emit("hover", {
          hovered: null,
          hoverPosition,
        });
        return;
      }

      const { skill, group, desc } = _.find(
        this.skillgroups,
        (d) => d.key === key
      );
      const fromValue = _.round(
        _.find(this.occ_from.skills, (d) => d.skill === key).value,
        1
      );
      const toValue =
        this.occ_to &&
        _.round(_.find(this.occ_to.skills, (d) => d.skill === key).value, 1);
      this.$emit("hover", {
        hoverPosition,
        hovered: {
          key,
          html: `
            <h4>${skill}</h4>
            <div>${group}</div>
            <p style='font-size: 0.85em; font-style: italic'>${desc}</p>

            <div>Skill importance for</div>
            ${this.hoverValueTemplate(
              this.occ_from.title,
              fromValue,
              "from",
              this.fromColor
            )}
            ${
              this.occ_to
                ? this.hoverValueTemplate(
                    this.occ_to.title,
                    toValue,
                    "to",
                    this.toColor
                  )
                : ""
            }
          `,
        },
      });
    },
    hoverValueTemplate(title, value, type, color) {
      return `
        <div>
          <span
            class="occ_title ${type}"
            style="
              ${type === "from" ? "background-color" : "border-color"}: ${color}
            ">${title}</span>
          ${value}
        </div>
`;
    },
  },
};
</script>

<style scoped>
.skills {
  display: inline-block;
  margin-bottom: 20px;
}

.visuals {
  position: relative;
  width: 100%;
}

svg,
canvas,
.labels {
  position: absolute;
  top: 0;
  left: 0;
}

canvas {
  pointer-events: none;
  transform: translate(-50%, -50%);
}

.petals {
  opacity: 0;
}

.desc {
  font-size: 0.85em;
  font-style: italic;
}
</style>

<template>
  <figure id="visual" class="sticky">
    <Pathway
      v-bind="{
        id: 'story',
        occ_from_code,
        ...pathway,
        ...pathwayDimensions,
      }"
      @hover="hover"
      @finishCalculations="setupGraphSteps"
    />
    <!-- DRAW SKILLS -->
    <div ref="skills">
      <Skills
        v-for="skill in skills"
        :key="`skill${skill.id}`"
        v-bind="skill"
        @hover="hover"
      />
    </div>
    <!-- HOVER -->
    <Hover v-show="hovered" v-bind="{ position: hoverPosition }">
      <div v-html="hovered && hovered.html" />
    </Hover>
  </figure>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { scaleLinear, forceSimulation, forceCollide } from "d3";
import enterView from "enter-view";
import { gsap } from "gsap";

import Mixin from "./components/Mixin";
import Skills from "./components/Skills.vue";
import Pathway from "./components/Pathway.vue";
import Hover from "./components/Hover";

export default {
  name: "Story",
  mixins: [Mixin],
  components: { Skills, Pathway, Hover },
  data() {
    return {
      width: null,
      height: null,
      skills: [],
      pathway: {
        showNodes: [],
        showLinks: [],
        highlightedNodes: [],
        highlightedLinks: [],
        pathways: [],
        toMaskOpacity: 1,
        collideRadius: 80,
      },
      hovered: null,
      hoverPosition: null,
    };
  },
  mounted() {
    this.width = this.$el.clientWidth;
    this.height = this.$el.clientHeight;

    this.setupAllSkills();
    this.setupTimeline();
  },
  computed: {
    ...mapState("story", ["occ_from_code", "occ_to_code", "steps"]),
    ...mapGetters("story", [
      "topSkills",
      "overlappingSkills",
      "commonTransitions",
      "bestGateway",
    ]),
    stepIds() {
      return _.map(this.steps, "id");
    },
    pathwayDimensions() {
      return { width: this.width, height: this.height };
    },
    singleVizDimensions() {
      const padding = 40;
      const size = Math.min(this.width * 0.5, window.innerHeight) - padding;

      return {
        toX: this.width * 0.75,
        toY: this.height * 0.5,
        toSize: size,
        toInnerRadius: 0.1 * size,
      };
    },
    multiVizDimensions() {
      const numViz = 3;
      const padding = 10;
      const numRows = 1;
      const perRow = Math.ceil(numViz / numRows);
      const size = Math.min(
        (this.width - padding * (numViz - 1)) / perRow,
        this.height / 2 - numViz * padding
      );
      const xOffset = (this.width - perRow * size - (perRow - 1) * padding) / 2;
      const yOffset =
        (this.height - numRows * size - (numRows - 1) * padding) / 2;
      return _.times(numViz, (i) => {
        return {
          toX: ((i % perRow) + 0.5) * (size + padding) + xOffset,
          toY: (Math.floor(i / perRow) + 0.5) * (size + padding) + yOffset,
          toSize: size,
          toInnerRadius: Math.max(0.1 * size, 36),
        };
      });
    },
  },
  watch: {
    topSkills() {
      this.setupAllSkills();
      this.setupTimeline();
    },
    commonTransitions() {
      this.setupAllSkills();
      this.setupTimeline();
    },
    bestGateway() {
      this.setupAllSkills();
      this.pathway.pathways = this.bestGateway;
      this.setupTimeline();
    },
  },
  methods: {
    setupAllSkills() {
      if (
        !this.topSkills.length ||
        !this.overlappingSkills.length ||
        !this.commonTransitions.length ||
        !this.bestGateway.length
      )
        return;

      const width = this.$el.clientWidth;
      const height = this.$el.clientHeight;
      const skills = _.chain([
        {
          id: this.occ_from_code,
          source: this.commonTransitions[0].source,
          target: null,
        },
      ])
        .unionBy(
          // occupations from N most common transitions
          this.commonTransitions,
          // occupations from pathway
          this.bestGateway
        )
        .uniqBy("id")
        .map(({ id, source, target }) => {
          const size = 540;
          const innerRadius = 10;
          const x = _.random(0.4 * width, 0.6 * width);
          const y = _.random(0.49 * height, 0.51 * height);
          const originalDimensions = {
            toX: x,
            toY: y,
            toSize: size,
            toInnerRadius: innerRadius,
            toMaskOpacity: 1,
            highlighted: [],
            showLabels: false,
            inPathway: false,
          };
          return {
            id,
            occ_from: source,
            occ_to: target,
            // FOR ANIMATION
            position: "absolute",
            show: true,
            x,
            y,
            // have those dimensions at top level
            // which will get updated on animation
            ...originalDimensions,
            // but also remember it in an object
            // when we need to reset to those dimensions
            originalDimensions,
          };
        })
        .value();

      const simulation = forceSimulation(skills)
        .force(
          "collide",
          forceCollide((d) => d.toSize / 3.5)
        )
        .stop();
      const xOffset = (window.innerWidth - this.$el.clientWidth) / 2;
      _.times(250, () => {
        simulation.tick();
        _.each(skills, (d) => {
          // left, right
          d.x = Math.max(
            -xOffset,
            Math.min(this.$el.clientWidth + xOffset, d.x)
          );
          // d.x = Math.max(0, Math.min(this.$el.clientWidth - d.toSize / 4, d.x));
          // top, bottom
          d.y = Math.max(0, Math.min(window.innerHeight, d.y));
        });
      });
      this.skills = _.each(skills, (d) =>
        Object.assign(d, { toX: d.x, toY: d.y })
      );
    },
    setupTimeline() {
      if (!this.skills.length) return;

      this.tl = gsap.timeline({ paused: true });
      _.each(this.steps, ({ func }, progress) => {
        this[`setup${func}`] && this[`setup${func}`](progress + 1);
      });

      const stepEls = document.querySelectorAll(".step");
      const fadeOutScale = scaleLinear([0, 0.5], [1, 0.25]).clamp(true);
      const fadeInScale = scaleLinear([0.5, 1], [0.25, 1]).clamp(true);
      enterView({
        selector: ".step",
        progress: (el, progress) => {
          const step = el.getAttribute("data-step");
          const index = _.indexOf(this.stepIds, step);
          this.tl.time(index + progress);

          // prev text box should fade out
          stepEls[index - 1] &&
            (stepEls[index - 1].style.opacity = fadeOutScale(progress));
          // current text book should fade in
          el.style.opacity = fadeInScale(progress);
        },
        offset: 0,
      });
    },
    setupSkillFrom(progress) {
      // now translate all but one to the sides
      const width = this.$el.clientWidth;
      const xOffset = (window.innerWidth - width) / 2;
      const left = -xOffset;
      const right = width + xOffset;
      const half = this.skills.length / 2;
      const perHeight = this.$el.clientHeight / half;
      _.each(this.skills, (d, i) => {
        const toX = i < half ? left : right;
        const toY = ((i % half) + 0.5) * perHeight;
        const toSize = 320;
        const toMaskOpacity = 0.5;
        Object.assign(d.originalDimensions, {
          toX,
          toY,
          toSize,
          toMaskOpacity,
        });
        // don't set the first one
        if (i === 0) return;
        this.tl.set(
          d,
          { toX, toY, toSize, toMaskOpacity },
          progress - this.duration
        );
      });
      // translate the first skill to the right center
      this.tl.set(
        this.skills[0],
        {
          ...this.singleVizDimensions,
          showLabels: true,
        },
        progress - this.duration
      );
    },
    setupSkillFromHighlight(progress) {
      this.tl.set(
        this.skills[0],
        { highlighted: _.map(this.topSkills, "skill"), toMaskOpacity: 0.25 },
        progress - this.duration
      );
    },
    setupSkillFromTo(progress) {
      // first skill, move back
      this.tl.set(
        this.skills[0],
        { ...this.skills[0].originalDimensions },
        progress - 1.5 * this.duration
      );

      // then move the second one to where first one was
      this.tl.set(
        this.skills[1],
        { ...this.singleVizDimensions, showLabels: true, toMaskOpacity: 1 },
        progress - this.duration
      );
    },
    setupSkillFromToHighlight(progress) {
      this.tl.set(
        this.skills[1],
        {
          highlighted: _.map(this.overlappingSkills, "skill"),
          toMaskOpacity: 0.25,
        },
        progress - this.duration
      );
    },
    setupSkillFromCommonTrans(progress) {
      // move back
      this.tl.set(
        this.skills[1],
        { ...this.skills[1].originalDimensions },
        progress - 1.5 * this.duration
      );

      const skills = _.chain(this.commonTransitions)
        .tail()
        .map(({ id }) => _.find(this.skills, (d) => d.id === id))
        .value();
      _.each(skills, (skill, i) => {
        //  move to center and show all labels
        this.tl.set(
          skill,
          { ...this.multiVizDimensions[i], showLabels: true, toMaskOpacity: 1 },
          progress - this.duration
        );
      });
    },
    setupGraphSteps(graph) {
      this.setupPathwaySkillFromFirstLeap(
        this.stepIds.indexOf("pathway-skill-from-first-leap") + 1,
        graph
      );
      this.setupPathwaySkillFromSecondLeap(
        this.stepIds.indexOf("pathway-skill-from-second-leap") + 1,
        graph
      );
      this.setupPathwayDemographicFrom(
        this.stepIds.indexOf("pathway-demographic-from") + 1,
        graph
      );
      this.setupPathwayDemographicFromHighlight(
        this.stepIds.indexOf("pathway-demographic-from-highlight") + 1,
        graph
      );
    },
    setupPathwaySkillFromFirstLeap(progress, graph) {
      if (!graph) return;
      // of the common transitions, keep those that will be in pathway
      const nodesByCode = _.keyBy(graph.nodes, "code");
      const keep = [
        this.skills[0],
        _.find(this.skills, ({ id }) => this.bestGateway[0].id === id),
      ];
      _.each(keep, (skill) => {
        const { occ_from, occ_to } = skill;
        const { x, y } = nodesByCode[occ_to ? occ_to.code : occ_from.code];
        this.tl.set(
          skill,
          {
            toX: x,
            toY: y,
            toSize: 2 * this.pathway.collideRadius,
            toInnerRadius: 20,
            toMaskOpacity: 1,
            showLabels: true,
            inPathway: true,
          },
          progress - 1.5 * this.duration
        );
      });
      // everything else move back and fade out label
      _.chain(this.skills)
        .difference(keep)
        .each((skill) => {
          this.tl.set(
            skill,
            { ...skill.originalDimensions },
            progress - 1.5 * this.duration
          );
        })
        .value();
      // finally, show pathway
      this.tl.set(
        this.pathway,
        { showLinks: [graph.links[0].id] },
        progress - this.duration
      );
    },
    setupPathwaySkillFromSecondLeap(progress, graph) {
      if (!graph) return;
      const nodesByCode = _.keyBy(graph.nodes, "code");
      // now move the rest of skills in best gateway, two leaps away
      _.each(this.bestGateway, ({ id }) => {
        const skill = _.find(this.skills, (d) => d.id === id);
        const { occ_from, occ_to } = skill;
        const { x, y } = nodesByCode[occ_to ? occ_to.code : occ_from.code];
        this.tl.set(
          skill,
          {
            toX: x,
            toY: y,
            toSize: 2 * this.pathway.collideRadius,
            toInnerRadius: 20,
            toMaskOpacity: 1,
            showLabels: true,
            inPathway: true,
          },
          progress - 1.5 * this.duration
        );
      });

      // then animate the paths in
      this.tl.set(
        this.pathway,
        { showLinks: _.map(graph.links, "id") },
        progress - this.duration
      );
    },
    setupPathwayDemographicFrom(progress, graph) {
      if (!graph) return;
      // put skills back
      _.each(this.skills, (skill) => {
        this.tl.set(skill, { show: false }, progress - this.duration);
      });
      // and show demographics
      this.tl.set(
        this.pathway,
        { showNodes: _.map(graph.nodes, "id") },
        progress - this.duration
      );
    },
    setupPathwayDemographicFromHighlight(progress, graph) {
      if (!graph) return;
      // only keep nodes and links with less % for demographics
      const originDemo = graph.nodes[0].occ_from.demographics;
      const nodes = _.chain(graph.nodes)
        .filter(({ occ_to }) => {
          return (
            occ_to &&
            _.chain(occ_to.demographics)
              .take(2)
              .every(({ percent }, i) => percent < originDemo[i].percent)
              .value()
          );
        })
        .map("id")
        .value();
      this.tl.set(
        this.pathway,
        {
          highlightedNodes: nodes,
          toMaskOpacity: 0.25,
        },
        progress - this.duration
      );
    },
    hover({ hovered, hoverPosition }) {
      this.hovered = hovered;
      this.hoverPosition = hoverPosition;
    },
  },
};
</script>

<style scoped>
#visual {
  width: 100%;
  /* FOR DEBUGGING */
  /* z-index: 1000; */
}

/* ANIMATION */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

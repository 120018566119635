<template>
  <div class="histogram">
    <div class="title">
      <h4>{{ detailedLabel || label }}</h4>
      <div class="clear" :style="{ color: colors.blue }" @click="clearBrush">
        Clear
      </div>
    </div>
    <svg :width="width" :height="height">
      <g class="bars">
        <!-- ALL BARS -->
        <rect
          v-for="({ x, y, width, height }, i) in bars"
          :key="`rect${i}`"
          :x="x"
          :y="y"
          :width="width"
          :height="height"
          :fill="colors.faded"
        />
        <!-- FILTERED BARS -->
        <rect
          v-for="({ x, y, width, height, fill }, i) in filteredBars"
          :key="`filteredRect${i}`"
          :x="x"
          :y="y"
          :width="width"
          :height="height"
          :fill="fill"
        />
      </g>
      <!-- MEDIAN -->
      <g
        class="median"
        :transform="`translate(${lineX}, ${0.75 * margin.top})`"
      >
        <line
          :y2="height - 0.75 * margin.top - margin.bottom"
          :stroke="colors.red"
          stroke-width="2"
        />
        <text y="-2" :fill="colors.red">median</text>
      </g>
      <g ref="xAxis" :transform="`translate(0, ${height - margin.bottom})`" />
      <g ref="brush" />
    </svg>
  </div>
</template>

<script>
import _ from "lodash";
import {
  max,
  median,
  extent,
  scaleLinear,
  histogram,
  select,
  axisBottom,
  brushX,
} from "d3";
import { mapActions } from "vuex";
// import { TweenMax } from "gsap";
import Mixin from "./Mixin";

export default {
  name: "histogram",
  mixins: [Mixin],
  props: {
    id: String,
    detailedLabel: String,
    label: String,
    format: Function,
    type: String,
    data: Array,
    filtered: Array,
  },
  data() {
    return {
      width: null,
      height: 100,
      bars: [],
      filteredBars: [],
      lineX: 0,
    };
  },
  mounted() {
    this.getDimensions();
    this.calculateScales();
    this.calculateData();
    this.renderAxes();

    this.brush = brushX()
      .extent([
        [0, this.margin.top],
        [this.width, this.height - this.margin.bottom],
      ])
      .on("brush", this.onBrush)
      .on("end", this.brushEnd);
    select(this.$refs.brush).call(this.brush);
  },
  watch: {
    data() {
      this.getDimensions();
      this.calculateScales();
      this.renderAxes();
    },
    filtered() {
      this.calculateData();
    },
  },
  methods: {
    ...mapActions("tool", ["updateFilter"]),
    getDimensions() {
      this.width = this.$el.clientWidth;
    },
    calculateScales: function() {
      if (!this.data.length) return;
      this.xScale = scaleLinear(
        extent(this.data, (d) => d[this.id]),
        [0, this.width]
      ).nice();

      // get the bins
      this.histogram = histogram()
        .domain(this.xScale.domain())
        .thresholds(this.xScale.ticks(Math.floor(this.width / 6)))
        .value((d) => d[this.id]);
      const bins = this.histogram(this.data);

      // calculate y from the bins
      const yMax = max(bins, (d) => d.length);
      this.heightScale = scaleLinear(
        [0, yMax],
        [0, this.height - this.margin.bottom - this.margin.top]
      );
    },
    calculateData() {
      if (!this.data.length) return;
      this.lineX = this.xScale(median(this.data, (d) => d[this.id]));
      this.bars = this.calculateBars(this.data) || [];
      this.filteredBars = this.calculateBars(this.filtered, true) || [];
    },
    calculateBars(data, stack) {
      if (!data.length) return;
      // use filtered for bins now that scales are calculated
      const bins = this.histogram(data);
      const { x1, x0 } = bins[0];
      const width = this.xScale(x1) - this.xScale(x0) - 1;
      return _.chain(bins)
        .map((d) => {
          const { x0 } = d;
          const x = this.xScale(x0) + 0.5;
          let y = this.height - this.margin.bottom;

          return _.chain(d)
            .groupBy(({ is23M }) => (stack ? is23M : ""))
            .sortBy(([{ is23M }]) => -is23M)
            .map((d) => {
              const height = this.heightScale(d.length);
              y -= height;

              return {
                x,
                y,
                width,
                height,
                fill: this.colorScale(d[0].is23M),
              };
            })
            .value();
        })
        .flatten()
        .value();

      // // calculate rect bar for each bin
      // this.bars = bins.map((d, i) => {
      //   const { x0, x1 } = d;
      //   const x = this.xScale(x0);
      //   const y = this.bars[i] ? this.bars[i].toY : this.yScale(0);
      //   const toY = this.yScale(d.length);
      //   const fill = this.colorScale(d3.median(d, (d) => d.score) || 0);
      //   return {
      //     id: i,
      //     x,
      //     width: this.xScale(x1) - x,
      //     y,
      //     toY,
      //     height: this.height - this.margin.bottom - y,
      //     toHeight: this.height - this.margin.bottom - toY,
      //     fill,
      //   };
      // });
      // and then animate them
      // const tween = TweenMax.staggerFromTo(
      //   this.bars,
      //   0.25,
      //   {
      //     // from
      //     cycle: {
      //       y: (i) => this.bars[i].y,
      //       height: (i) => this.bars[i].height,
      //     },
      //   },
      //   {
      //     // to
      //     cycle: {
      //       y: (i) => this.bars[i].toY,
      //       height: (i) => this.bars[i].toHeight,
      //     },
      //   }
      // );
    },
    renderAxes: function() {
      if (!this.data.length) return;
      const xAxis = axisBottom()
        .scale(this.xScale)
        .tickFormat(this.format)
        .tickSizeOuter(0)
        .ticks(8);
      select(this.$refs.xAxis).call(xAxis);
    },
    clearBrush() {
      select(this.$refs.brush).call(this.brush.clear);
    },
    onBrush(event) {
      const bounds = this.calculateBounds(event);
      if (!bounds) {
        return this.brushEnd();
      }
      const [minBound, maxBound] = bounds;
      const filtered = _.filter(
        this.data,
        (d) => minBound <= d[this.id] && d[this.id] < maxBound
      );
      this.filteredBars = this.calculateBars(filtered, true);
    },
    brushEnd(event) {
      this.updateFilter({
        key: this.id,
        type: this.type,
        bounds: this.calculateBounds(event),
      });
    },
    calculateBounds(event) {
      let bounds = null;
      if (event && event.selection) {
        const [x1, x2] = event.selection;
        bounds = [this.xScale.invert(x1), this.xScale.invert(x2)];
      }
      return bounds;
    },
  },
};
</script>

<style scoped>
.histogram {
  display: inline-block;
}

.title {
  display: grid;
  grid-template-columns: auto min-content;
  align-items: baseline;
  grid-gap: 10px;
}

.title .clear {
  font-size: 0.85em;
  cursor: pointer;
}

.median text {
  text-anchor: middle;
  font-size: 11px;
}
</style>

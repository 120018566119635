import _ from "lodash";

export default {
  namespaced: true,
  state: {
    occ_from_code: "3600",
    steps: [
      { id: "intro", func: "Intro" },
      { id: "skill-from", func: "SkillFrom" },
      { id: "skill-from-highlight", func: "SkillFromHighlight" },
      { id: "skill-from-to", func: "SkillFromTo" },
      { id: "skill-from-to-highlight", func: "SkillFromToHighlight" },
      {
        id: "skill-from-common_transitions",
        func: "SkillFromCommonTrans",
      },
      {
        id: "pathway-skill-from-first-leap",
        func: "PathwaySkillFromFirstLeap",
      },
      {
        id: "pathway-skill-from-second-leap",
        func: "PathwaySkillFromSecondLeap",
      },
      {
        id: "pathway-demographic-from",
        func: "PathwayDemographicFrom",
      },
      {
        id: "pathway-demographic-from-highlight",
        func: "PathwayDemographicFromHighlight",
      },
    ],
  },

  getters: {
    occ_from({ occ_from_code }, getters, { occupations }) {
      return _.find(occupations, ({ code }) => code === occ_from_code);
    },
    topSkills(state, { occ_from }) {
      return _.chain(occ_from && occ_from.skills)
        .sortBy(({ value }) => -value)
        .take(5)
        .value();
    },
    overlappingSkills(state, { commonTransitions }) {
      if (!commonTransitions.length) return;
      const {
        source: { skills: fromSkills },
        target: { skills: toSkills },
      } = commonTransitions[0];
      return _.filter(fromSkills, ({ value: fromValue }, i) => {
        if (fromValue < 2) return;
        const { value: toValue } = toSkills[i];
        return fromValue - 0.1 <= toValue && toValue <= fromValue + 0.1;
      });
    },
    commonTransitions(
      { occ_from_code },
      getters,
      rootState,
      { getTransitions }
    ) {
      return _.take(
        getTransitions(
          occ_from_code,
          null,
          (percentDiff) => percentDiff >= 0.1,
          "percent"
        ),
        4
      );
    },
    bestGateway(state, { commonTransitions }, rootState, { getTransitions }) {
      if (!commonTransitions.length) return;
      let { first, seconds } = _.chain(commonTransitions)
        .map((first) => {
          const {
            target: { code },
          } = first;

          return {
            first,
            seconds: _.take(
              getTransitions(
                code,
                null,
                (percentDiff) => percentDiff >= 0.1,
                "percent"
              ),
              5
            ),
          };
        })
        .filter(({ seconds }) => seconds.length === 5)
        .sortBy(({ first: { percent } }) => -percent)
        .value()[0];

      first = { ...first, index: 1 };
      seconds = _.map(seconds, (second) => {
        return {
          ...second,
          distance: first.distance + second.distance,
          hourlyDiff: first.hourlyDiff + second.hourlyDiff,
          index: 2,
        };
      });
      return _.union([first], seconds);
    },
  },
};

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"histogram"},[_c('div',{staticClass:"title"},[_c('h4',[_vm._v(_vm._s(_vm.detailedLabel || _vm.label))]),_c('div',{staticClass:"clear",style:({ color: _vm.colors.blue }),on:{"click":_vm.clearBrush}},[_vm._v(" Clear ")])]),_c('svg',{attrs:{"width":_vm.width,"height":_vm.height}},[_c('g',{staticClass:"bars"},[_vm._l((_vm.bars),function(ref,i){
var x = ref.x;
var y = ref.y;
var width = ref.width;
var height = ref.height;
return _c('rect',{key:("rect" + i),attrs:{"x":x,"y":y,"width":width,"height":height,"fill":_vm.colors.faded}})}),_vm._l((_vm.filteredBars),function(ref,i){
var x = ref.x;
var y = ref.y;
var width = ref.width;
var height = ref.height;
var fill = ref.fill;
return _c('rect',{key:("filteredRect" + i),attrs:{"x":x,"y":y,"width":width,"height":height,"fill":fill}})})],2),_c('g',{staticClass:"median",attrs:{"transform":("translate(" + _vm.lineX + ", " + (0.75 * _vm.margin.top) + ")")}},[_c('line',{attrs:{"y2":_vm.height - 0.75 * _vm.margin.top - _vm.margin.bottom,"stroke":_vm.colors.red,"stroke-width":"2"}}),_c('text',{attrs:{"y":"-2","fill":_vm.colors.red}},[_vm._v("median")])]),_c('g',{ref:"xAxis",attrs:{"transform":("translate(0, " + (_vm.height - _vm.margin.bottom) + ")")}}),_c('g',{ref:"brush"})])])}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="tool">
    <h2>Job Pathways Exploration Tool</h2>
    <p>
      This tool allows you to explore job pathways workers have experienced in
      the labor market. Each pathway consists of two transitions. Every
      job-to-job transition illustrated here has been made by at least 2,000
      workers over the past decade.
    </p>
    <div id="histograms">
      <div style="grid-column: 1/4">
        <h3>1. Filter occupations</h3>
        <em
          >Click and drag along the horizontal axis to filter occupations by
          wage, gender and race.</em
        >
      </div>
      <Histogram
        v-for="(props, i) in occHistograms"
        :key="`occFilter${i}`"
        v-bind="{ ...props, width: 300 }"
      />
    </div>
    <div>
      <h3>2. Select an occupation</h3>
      <Dropdown
        v-bind="{ selected: occ_from_code, occupations: filteredOccupations }"
        @selectOption="(code) => setOccFrom(code)"
      />
    </div>
    <div class="occupation">
      <!-- TOP LEFT: DESTINATION FILTER-->
      <div style="grid-row: 1/3">
        <div id="destFilters" ref="destFilters">
          <div style="grid-column: 1/3">
            <h3>
              3. Explore possible job pathways
            </h3>
            <em>
              Click on the links below to see potential pathways from your
              chosen occupation:
            </em>
          </div>
          <div
            class="label"
            v-for="({ key, label, selected }, i) in Object.values(destFilters)"
            :key="`label${key}`"
            :style="{
              color: selected ? colors.blue : colors.disabled,
              gridArea: `${i + 2} / 1 / ${i + 3} / 2`,
            }"
            @click="updateFilter({ key, type: 'destFilters' })"
          >
            {{ label }}:
          </div>
          <div
            class="description"
            v-for="({ key, description, selected }, i) in Object.values(
              destFilters
            )"
            :key="`desc${key}`"
            :style="{
              color: selected ? colors.blue : colors.disabled,
              gridArea: `${i + 2} / 2 / ${i + 3} / 3`,
            }"
            @click="updateFilter({ key, type: 'destFilters' })"
          >
            {{ description }}
          </div>
        </div>
        <!-- BOTTOM RIGHT -->
        <Pathway
          v-bind="{
            id: 'tool',
            occ_from_code,
            pathways,
            ...pathwayDimensions,
          }"
          @hover="hover"
        />
      </div>
      <!-- TOP RIGHT: LEGENDS -->
      <div class="legend" ref="legend" style="grid-column: 2/3; grid-row:1/3">
        <h4>How to read these visualizations:</h4>
        <img src="images/demographic.jpg" />
        <img src="images/path.jpg" />
        <img src="images/overlapping.jpg" />
      </div>
      <!-- BOTTOM: SKILLS -->
      <div style="grid-column: 1/ 3">
        <h3>4. Explore occupation skills</h3>
        <div
          class="skills-container"
          v-for="({ title, skills }, i) in skills"
          :key="`skill${i}`"
        >
          <hr v-if="title" />
          <div v-if="title" class="title">{{ title }}</div>
          <Skills
            v-for="skill in skills"
            v-bind="{
              ...skill,
              toSize: skillsSize,
              toInnerRadius: Math.max(0.1 * skillsSize, 36),
            }"
            :key="`skill${skill.id}`"
            @hover="hover"
          />
        </div>
      </div>
    </div>
    <!-- HOVER -->
    <Hover v-show="hovered" v-bind="{ position: hoverPosition }">
      <div v-html="hovered && hovered.html" />
    </Hover>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import _ from "lodash";

import Mixin from "./components/Mixin";
import Skills from "./components/Skills.vue";
import Pathway from "./components/Pathway.vue";
import Histogram from "./components/Histogram.vue";
import Dropdown from "./components/Dropdown.vue";
import Hover from "./components/Hover";

export default {
  name: "Tool",
  mixins: [Mixin],
  components: { Skills, Pathway, Histogram, Dropdown, Hover },
  data() {
    return {
      pathwayDimensions: {},
      hovered: null,
      hoverPosition: null,
    };
  },
  computed: {
    ...mapState(["occupations"]),
    ...mapState("tool", ["occ_from_code", "originFilters", "destFilters"]),
    ...mapGetters("tool", [
      "occ_from",
      "possibleTransitions",
      "filteredOccupations",
      // getters for phrases
      "mostCommonTransitions",
      "nextBestTransitions",
      "stretchJobTransitions",
    ]),
    occHistograms() {
      return _.map(
        this.originFilters,
        ({ key, detailedLabel, label, format, access }) => {
          return {
            id: key,
            detailedLabel,
            label,
            type: "originFilters",
            format,
            data: _.map(this.occupations, (d) => {
              return { is23M: d.is23M, [key]: access(d) };
            }),
            filtered: _.map(this.filteredOccupations, (d) => {
              return { is23M: d.is23M, [key]: access(d) };
            }),
          };
        }
      );
    },
    pathways() {
      const { getter } = _.find(this.destFilters, "selected");
      return this[getter];
    },
    skills() {
      if (!this.pathways.length) {
        return [{ skills: [{ id: `tool_from`, occ_from: this.occ_from }] }];
      }
      return _.chain(this.pathways)
        .groupBy("index")
        .map((skills, index) => {
          return {
            title: `${+index === 1 ? "One leap" : "Two leaps"} away`,
            skills: _.chain(skills)
              .sortBy(({ percentDiff }) => percentDiff)
              .map(({ id, source, target }) => {
                return {
                  id: `tool_${id}`,
                  occ_from: source,
                  occ_to: target,
                };
              })
              .value(),
          };
        })
        .value();
    },
    skillsSize() {
      if (!this.pathways.length) return 280;
      const numViz = 5;
      const width = this.$el.clientWidth;
      const padding = 1;
      return (width - padding * (numViz - 1)) / numViz;
    },
  },
  mounted() {
    this.pathwayDimensions = {
      width: this.$refs.destFilters.clientWidth,
      height:
        this.$refs.legend.clientHeight - this.$refs.destFilters.clientHeight,
    };
  },
  watch: {
    pages() {
      if (!this.pages.length) return;
      // if page doesn't exist
      const exists = _.find(this.pages, ([start]) => this.page[0] === start);
      if (!exists) {
        this.page = this.pages[0];
      }
    },
  },
  methods: {
    ...mapMutations("tool", ["setOccFrom"]),
    ...mapActions("tool", ["updateFilter"]),
    hover({ hovered, hoverPosition }) {
      this.hovered = hovered;
      this.hoverPosition = hoverPosition;
    },
  },
};
</script>

<style scoped>
#histograms {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, min-content);
  align-items: end;
  column-gap: 20px;
  margin: 20px 0;
}

h2,
h3,
h4 {
  margin: 10px 0;
}

.occupation {
  display: grid;
  grid-template-columns: auto 320px;
  grid-template-rows: repeat(3, min-content);
  grid-gap: 20px 80px;
  max-width: 100%;
  margin: 20px 0;
}

#destFilters {
  width: 100%;
  height: min-content;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: repeat(4, min-content);
  column-gap: 10px;
  align-items: baseline;
}

/* DESTINATION FILTERS */
.label,
.description {
  cursor: pointer;
  line-height: 1.6;
}

.label {
  font-weight: bold;
  white-space: nowrap;
}

.description {
  font-size: 0.85em;
  font-style: italic;
}

/* LEGEND */
.legend {
  height: min-content;
}

/* SKILLS */
.skills-container {
  display: inline-block;
  position: relative;
}

.title {
  position: absolute;
  text-align: center;
  background: #fff;
  top: 0;
  left: 50%;
  transform: translate(-50%, -25%);
  padding: 0 10px;
  white-space: nowrap;
}
</style>

import { render, staticRenderFns } from "./Demographics.vue?vue&type=template&id=10311a31&scoped=true&"
import script from "./Demographics.vue?vue&type=script&lang=js&"
export * from "./Demographics.vue?vue&type=script&lang=js&"
import style0 from "./Demographics.vue?vue&type=style&index=0&id=10311a31&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10311a31",
  null
  
)

export default component.exports